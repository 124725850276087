<template>
  <pt-sample-detail class="pt-sample-detail__withData" :data="data" :order="order" :id="id" :bill="bill" @load="getSampleDetail"></pt-sample-detail>
</template>

<script>
import PtSampleDetail from './index'
export default {
  name: 'pt-sample-detail__withData',
  components: {
    'pt-sample-detail': PtSampleDetail
  },
  props:{
    id:String
  },
  data (){
    return {
      data:{},
      order:{},
      bill:{}
    }
  },
  methods:{
    getSampleDetail (){
      this.$loading()
      this.$store.dispatch('getSampleDetail', this.id).then(res => {
        this.$loading.hide()
        this.data = res
        let {order, bill, status} = res
        this.order = order
        this.bill = bill
      }).catch(err => {
        this.$loading.hide()
        this.$toast.error(err || '获取样品信息失败')
      })
    }
  },
  created (){
    this.getSampleDetail()
  }
}
</script>
