<template>
  <pt-sample-detail class="pt-sample-detail__page" :id="id"></pt-sample-detail>
</template>

<script>
import PtSampleDetail from 'cps//pt-sample-detail/withData'
export default {
  name: 'pt-sample-detail__view',
  components: {
    'pt-sample-detail': PtSampleDetail
  },
  props:{
    id:String
  }
}
</script>
