<template>
<div :class="$style['top-message']">
		<div class="title-status" :style="{ borderColor: statusColor }">
		<div class="title">
			<div class="code-title">样品编号：</div>
			<div>{{order.orderCode||'--'}}</div>
		</div>
		<div  :style="{ color: statusColor }">{{order.orderStatus}}</div>
		</div>
</div>
</template>
<script>
export default {
  props:{
    order:{
      type:Object,
      default:function () {
        return {}
      }
    },
    statusColor:String
  }
}
</script>
<style lang="less" module>
@import "~less/var.less";
.top-message{
	:global{
		.title-status{
			display:flex;
      justify-content: space-between;
      width: 100%;
      background-color:#fff;
			border-left:solid 5px @main-color;
			box-sizing: border-box;
			padding:20px 20px;
		}
		.code-title{
			font-size: @font-size-min;
			color: @font-of-grey
		}
		.title{
			display:flex
		}
	}
}

</style>