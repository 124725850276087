<template>
  <div :class="$style[classes]">
	<top-message :order="order" :statusColor="statusColor"></top-message>
	<div class="sample-message">
	<main-message :order="order" :statusColor="statusColor"></main-message>
	</div>
	<div :class="$style['sample-footer']" v-if="showFooterButtom">
		<status-operation :order="order" :id="id" :bill="bill" @load="load"></status-operation>
	</div>
	</div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-sample-detail'
import topMessage from "./top-message"
import mainMessage from "./main-message"
import statusOperation from "./status-operation"
export default {
  name: 'pt-sample-detail',
  components:{
    topMessage, 
    mainMessage,
    statusOperation
  },
  computed: {
    classes () {
      return `${prefixCls}`
    },
    statusColor (){
      return this.order.billStatusEnum === 'BILL_COMPLETED' ? '#ADADAD' : "#409EFF"
    },
    showFooterButtom (){
      return this.order.orderStatusEnum !== 'COMPLETED'&&this.order.orderStatusEnum !== 'CLOSED'
    }
  },
  props:{
    id:String,
    data:{
      type:Object,
      default:function (){
        return {}
      }
    },
    order:{
      type:Object,
      default:function (){
        return {}
      }
    },
    bill:{
      type:Object,
      default:function (){
        return {}
      }
    }
  },
  methods:{
    load (){
      this.$emit('load')
    }
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-sample-detail{
	height: 100%;
	overflow-y: auto;
	:global{
		.sample-message{
			padding: 10px @page-padding 80px;
		}
	}
}
.sample-footer{
	position: fixed;
	width: 100%;
	bottom: 0
}
</style>
