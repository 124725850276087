<template>
  <div :class="$style['content']">
		<table>
			<tr>
				<td>实验室唯一码：</td>
				<td>{{order.baseLabCode || '--'}}</td>
			</tr>
			<tr>
				<td>检测项目：</td>
				<td>{{order.labExamItem&&order.labExamItem.itemName||'--'}}</td>
			</tr>
			<tr>
				<td>检测依据：</td>
				<td>{{order.labExamItem&&order.labExamItem.examBasis&&order.labExamItem.examBasis.name ||"--"}}</td>
			</tr>
			<tr>
				<td>样品名称：</td>
				<td>{{order.orderName || '--'}}</td>
			</tr>
			<tr>
				<td>客户标识：</td>
				<td>{{order.customerCode}}</td>
			</tr>
			<tr>
				<td>颜色：</td>
				<td>{{order.color || '--'}}</td>
			</tr>
			<tr>
				<td>形态：</td>
				<td>{{order.shape || '--'}}</td>
			</tr>
			<tr>
				<td>包装：</td>
				<td>{{order.pack || '--'}}</td>
			</tr>
			<tr>
				<td>生产单位：</td>
				<td>{{order.productionUnit || '--'}}</td>
			</tr>
			<tr>
				<td>生产日期：</td>
				<td>{{order.orderProductDate || '--'}}</td>
			</tr>
			<tr>
				<td>生产批号：</td>
				<td>{{order.productBatchCode || '--'}}</td>
			</tr>
			<tr>
				<td>商标：</td>
				<td>{{order.brand || '--'}}</td>
			</tr>
			<tr>
				<td>规格/型号：</td>
				<td>{{order.specificationModel || '--'}}</td>
			</tr>
			<tr>
				<td>数量：</td>
				<td>{{order.orderCount || '--'}}</td>
			</tr>
			<tr>
				<td>存放条件：</td>
				<td>{{order.storage||'--'}}</td>
			</tr>
			<tr>
				<td>委托单编号：</td>
				<td>{{order.billCode || '--'}}</td>
			</tr>
			<tr>
				<td>是否偏离：</td>
				<td>{{order.labExamItem&&(order.labExamItem.deviate?'是':'否')}}</td>
			</tr>
			<tr>
				<td>是否返还：</td>
				<td>{{order.back_text}}</td>
			</tr>
			<tr>
				<td>预计交付日期：</td>
				<td>{{order.delivery_date}}</td>
			</tr>
			<tr>
				<td>实验人员：</td>
				<td>{{(order.otherInfo&&order.otherInfo.orderExamUser)||order.experimenter||'--'}}</td>
			</tr>
			<tr>
				<td>检测次数：</td>
				<td>{{order.testTimes}}</td>
			</tr>
			<tr>
				<td>交付方式：</td>
				<td>{{order.delivery_type||"--"}}</td>
			</tr>
			<tr>
				<td>待操作状态：</td>
				<td :style="{color:statusColor}">{{order.nextOrder_action||"--"}}</td>
			</tr>
		</table>
  </div>
</template>
<script>
export default {
  name: "main-message",
  props: {
    order:{
      type:Object,
      default:() => {}
    },
    statusColor:String
  },
  data () {
    return {
     
    };
  },
  watch: {
   
  },
  
  methods: {
    
  }
};
</script>
<style module lang="less">
@import "~less/var.less";
.content{
    padding: 15px 15px 0;
    margin-top: 15px;
    background-color: #fff;
		text-align: left;
		border-radius: 5px;
		table{
			tr{
				td{
					line-height: 20px;
					&:first-child{
						color: @font-of-grey;
						white-space: nowrap;
						padding: 5px 0;
					}
				}
			}
		}
  }
</style>
