<template>
	<div :class="$style['status-operation']">
		<div></div>
			<div :class="$style['buttons']">
				<!--待接收-->
				<template v-if="isORDER_RECEIVED">
					<wt-button outline danger @click="getReceived(true)">样本异常</wt-button>
					<wt-button primary @click="getReceived">样本正常</wt-button>
				</template>
				<template v-if="isORDER_ASSIGN">
					<wt-button primary @click="getReceived(true)">新建任务单</wt-button>
					<wt-button primary @click="getReceived">添加至任务单</wt-button>
				</template>
				<template v-if="isORDER_GET">
					<wt-button primary @click="getReceivedNoForm">确认已领样</wt-button>
				</template>
				<template v-if="isORDER_EXAM">
					<wt-button outline danger @click="getReceived">复测</wt-button>
					<wt-button primary @click="getReceivedNoForm">确认已提交数据</wt-button>
				</template>
				<template v-if="isREPORT_CREATE">
					<wt-button primary @click="getReceivedNoForm" :disabled="isReceiveExamData">确认已接收数据</wt-button>
					<wt-button primary @click="getReceivedNoForm" :disabled="!isReceiveExamData">确认已编制数据</wt-button>
				</template>
				<template v-if="isREPORT_CHECKED">
					<wt-button primary @click="getReceivedNoForm">确认已审核报告</wt-button>
				</template>
				<template v-if="isORDER_BACK">
					<wt-button primary @click="getReceivedNoForm" :disabled="isOrderBack">确认已归还</wt-button>
					<wt-button primary @click="getReceivedNoForm" :disabled="!isOrderBack">确认已回收</wt-button>
				</template>
			</div>
	</div>
</template>
<script>
const ORDER_RECEIVED = 'ORDER_RECEIVED'
const ORDER_ASSIGN = 'ORDER_ASSIGN'
const ORDER_GET = 'ORDER_GET'
const ORDER_EXAM = 'ORDER_EXAM'
const REPORT_CREATE = "REPORT_CREATE"
const REPORT_CHECKED = 'REPORT_CHECKED'
const ORDER_BACK = 'ORDER_BACK'
import {next} from "./com/status"
export default {
  props:{
    order:{
      type:Object,
      default:() => {}
    },
    id:String,
    bill:{
      type:Object,
      default:() => {}
    }
  },
  computed:{
    nextOrderAction (){
      return this.order.nextOrderAction
    },
    //
    isORDER_RECEIVED (){
      return this.nextOrderAction === ORDER_RECEIVED
    },
    isORDER_ASSIGN (){
      return this.nextOrderAction === ORDER_ASSIGN
    },
    isORDER_GET (){
      return this.nextOrderAction === ORDER_GET
    },
    isORDER_EXAM (){
      return this.nextOrderAction === ORDER_EXAM
    },
    isREPORT_CREATE (){
      return this.nextOrderAction === REPORT_CREATE
    },
    isREPORT_CHECKED (){
      return this.nextOrderAction === REPORT_CHECKED
    },
    isORDER_BACK (){
      return this.nextOrderAction === ORDER_BACK
    },
    isReceiveExamData () {
      return this.order.isReceiveExamData
    },
    isOrderBack () {
      return this.order.isOrderBack
    }
  },
  methods:{
    noFormData (){
      let orderStatusResult = null
      switch(this.nextOrderAction){
      case 'ORDER_EXAM':
        orderStatusResult = 'CONFIRM_EXAM_DATA'
        break
      case 'REPORT_CREATE':
        if(this.isReceiveExamData){
          orderStatusResult = 'REPORT_CREATED'
        }else{
          orderStatusResult = 'RECEIVE_EXAM_DATA'
        }
        break
      case 'REPORT_CHECKED':
        orderStatusResult = 'CONFIRM_EXAM_DATA'
        break
      case 'ORDER_BACK':
        if(this.isOrderBack){
          orderStatusResult = 'CONFIRM_ORDER_RECOVERY'
        }else{
          orderStatusResult = 'CONFIRM_ORDER_BACK'
        }
        break
      default:
        break
      }
      let data = {
        orderStatusResult:orderStatusResult
      }
      return data
    },
    getReceivedNoForm (){
      let data = {
        action:this.nextOrderAction,
        billId:this.bill.id,
        orderIds:[this.order.id],
        orderStatusDetail:{
          action:this.nextOrderAction,
          ...this.noFormData()
        }
      }
      next(data, true).then(res => {
        setTimeout(_ => {
          this.$emit('load')
        }, 1000)
      })
    },
    getReceived (isQuery){
      let query = {
      }
      switch(this.nextOrderAction){
      case 'ORDER_RECEIVED':
        query.isAbnormal = isQuery
        break
      case 'ORDER_ASSIGN':
        query.isCreat = isQuery
        break
      default:
        break
      }
      this.$router.push({path:`/sample-status/${this.nextOrderAction}/${this.id}`, query:{...query}})
    }
  }
}
</script>
<style module lang="less">
.status-operation{
  height: 100%;
  display: flex;
  justify-content: space-between;
	align-items: center;
	background-color: #fff;
	padding: 10px;
}
.tip{

}
.buttons{
	display: flex;
	:global{
	.cube-btn{
		margin-right: 10px;
	}
	}
}
</style>